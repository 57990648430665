/* Header.css */
.header-container {
    width: 100%;
    background: rgba(255, 0, 0, 0.3);  /* semi-transparent background */
    backdrop-filter: blur(10px);  /* blur effect */
    padding: 2px 0;
    display: flex;
    margin-top: -3.5rem;
    justify-content: center;
    align-items: center;
     /* Optional: adds a subtle shadow */
    transition: transform 1s ease-in-out, padding 1s ease-in-out, margin-bottom 1s ease-in-out;
  }
  
  .header-container.folded {
    transform: translateY(-100%); /* Slide up */
    padding: 0; /* Remove padding when folded */
    margin-bottom: -60px;
  }
  
  .header-title {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.9); /* Darker text color */
    text-align: center;
  }
  