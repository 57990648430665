@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0px !important;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  
}

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 80px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:#0069fc;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #575C66;
    border-radius: 6px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #626970;
  }

  /* App.css */
.app-container {
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.header-nav-container.folded {
  transform: translateY(-50px); /* Adjust the value to the height of the header */
}

.header-container.folded {
  transform: scaleY(0); /* Fold vertically */
  padding: 0; /* Remove padding when folded */
}
