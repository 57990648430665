.header {
  font-size: 2rem;
  padding-top:5px;
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.9); /* Darker text color */
  text-align: center;
  background: rgba(255, 255, 255, 0.3);
  transition: background-color 1s ease-in-out;
  
}



.submenu {
  position: absolute;
  margin-top: 56%;
  top: 100%;
  left: 0;
  background-image: linear-gradient(200deg, rgba(255, 0, 0, 0.3), rgba(0, 0, 255, 0.19));
  backdrop-filter: blur(8px); /* Add blur effect */
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

/* Hide header on mobile devices */
@media (max-width: 768px) {
  .header {
    display: none;
  }
  
}
