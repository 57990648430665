/* Sidebar styling */
.sidebar {
  position: fixed;
  top: 23rem;
  left: 0;
  width: 10%; /* Initial width when partially open */
  min-width: 50px; /* Minimum width to prevent it from collapsing completely */
  max-width: 120px; /* Maximum width for expanded state */
  height: auto;
  background: linear-gradient(90deg, rgba(255, 0, 0, 0.3), rgba(0, 0, 255, 0.3)); /* Gradient background with opacity */
  backdrop-filter: blur(10px); /* Blur effect */
  border-radius: 0 10px 10px 0; /* Rounded corners on the left */
  z-index: 99;
  transition: width 0.3s ease-in-out; /* Smooth sliding animation */
  
}

.sidebar.expanded {
  width: 200px; /* Adjust width for expanded state */
}

.toggle-button {
  position: absolute;
  top: 50%;
  right: 5px; /* Position inside the sidebar */
  transform: translateY(-50%);
  cursor: pointer;
  padding: 5px; /* Smaller padding for smaller icon */
  background: rgba(255, 255, 255, 0.0);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-button i {
  font-size: 20px; /* Icon size */
  color: #fff; /* Icon color */
}

.language-toggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure the animation is contained */
  width: 100%;
  padding: 10px; /* Add padding to align items */
}

.language-option {
  cursor: pointer;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.15); /* Semi-transparent background */
  border-radius: 10px;
  color: #fff; /* Text color */
  font-size: 14px; /* Adjust font size as needed */
  margin: 5px 0;
  text-align: center;
  transition: background 0.3s, transform 0.3s;
}

.language-option.selected {
  background: rgba(255, 255, 255, 0.4); /* Change background for selected option */
}

.globe {
  margin-top: 100px;
  padding-left: 30px;;
  margin-left: -170px;
  background: linear-gradient(170deg, rgba(255, 0, 0, 0.4), rgba(0, 0, 255, 0.4)); /* Gradient background with opacity */
  backdrop-filter: blur(10px); 
  border-radius: 20px 20px 20px 20px;
  padding: 3px 5px 0px 5px;


  
}

@media screen and (max-width: 600px) {
  .globe {
    margin-top: 100px;
    padding-left: 30px;;
    padding: 2px 2px 2px 2px;
    margin-left: 30%;
    background: linear-gradient(170deg, rgba(255, 0, 0, 0.4), rgba(0, 0, 255, 0.4)); /* Gradient background with opacity */
    backdrop-filter: blur(10px); 
    border-radius: 20px 20px 20px 20px;
    padding: 3px 5px 0px 5px;
  }
}